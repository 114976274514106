const memberStore = {
    namespaced: true,
    state: () => ({
        username: '',
        name: '',
        phoneNumber: '',
        token: '',
        roles: '',
    }),
    getters: {
        isLoggedIn(state) {
            return state.token && state.token.length > 1;
        },
        getLoggedInMemberData(state) {
            return {
                username: state.username,
                name: state.name,
                phoneNumber: state.phoneNumber,
                token: state.token,
                roles: state.roles
            }
        }
    },
    mutations: {
        clearSession(state) {
            state.username = '';
            state.name = '';
            state.phoneNumber = '';
            state.token = '';
            state.roles = '';
        },
        login(state, loggedInMember) {
            state.username = loggedInMember.data.username;
            state.name = loggedInMember.data.name;
            state.phoneNumber = loggedInMember.data.phoneNumber;
            state.token = loggedInMember.data.token;
            state.roles = loggedInMember.data.roles;
        }
    }
};

export default memberStore;