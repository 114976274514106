import Api from '@/server/api';

const URLS = Object.freeze({
    LOGIN: "/api/v1/auth",
    LOGIN_BY_COOKIE: "/api/v1/auth/cookie",
});

class AuthApi extends Api {
    constructor(axios) {
        super();
        this.axios = axios;
    }
    
    login(username, password) {
        return this.axios.post(URLS.LOGIN, { username, password });
    }
    
    loginByCookie(cookie) {
        return this.axios.post(URLS.LOGIN_BY_COOKIE, { 'cookie' : cookie });
    }
}

export default AuthApi;