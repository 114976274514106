import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import memberStore from '@/setup/storemodule/member-store';

export const store = createStore({
    modules: {
        member: memberStore
    },
    plugins: [createPersistedState()]
});