import {store} from '@/setup/store';

class Member {
    constructor(data) {
        this.data = data;
    }
    
    static fromResponse(response) {
        return new Member(response.data.data);
    }
    
    static fromClient() {
        if (store.getters['member/isLoggedIn']) {
            return new Member(store.getters['member/getLoggedInMemberData']);
        }
    }
    
    commitStore(store) {
        store.commit('member/login', this);
    }
    
    logout(store) {
        store.commit('member/clearSession');
    }
}

export default Member;