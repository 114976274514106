class Api {
    appendQueryString(path, query) {
        let queryStrings = [];
        Object.entries(query)
              .filter(eachEntire => eachEntire[1] !== undefined)
              .forEach(eachEntire => {
                  queryStrings.push(eachEntire[0] + '=' + eachEntire[1]);
              });
        const queryString = queryStrings.join('&');
        if (queryString && queryString.length > 0) {
            return `${path}?${queryString}`;
        }
        return path;
    }
}

export default Api;