import {createRouter, createWebHistory} from 'vue-router';
import {store} from '@/setup/store';
import AuthApi from '@/server/auth-api';
import axios from 'axios';
import Member from '@/server/response/member';

const authApi = new AuthApi(axios);

function beforeGuard(to, from, next) {
    if (isLoggedIn()) {
        return next();
    }
    if (!from.meta.disableSharedLogin) {
        setUpSharedLoginCookie();
    }
    const auth = to.meta.auth;
    if (!isValidAuth(auth)) {
        if (auth.redirect.startsWith('http')) {
            window.location.href = auth.redirect;
            return;
        }
        return next(auth.redirect);
    }
    return next();
    
    
    function isLoggedIn() {
        return store.getters['member/isLoggedIn'];
    }
    
    function setUpSharedLoginCookie() {
        if (isLoggedIn()) {
            return;
        }
        const cookie = getSharedLoginCookie();
        if (!cookie) {
            return;
        }
        authApi.loginByCookie(cookie)
               .then(response =>
                   Member.fromResponse(response)
                         .commitStore(store)
               );
        
        function isLoggedIn() {
            return store.getters['member/isLoggedIn'];
        }
        
        function getSharedLoginCookie() {
            const SHARED_LOGIN_COOKIE_NAME = 'member';
            const cookie = document.cookie
                                   .split(';')
                                   .map(each => each.split('='))
                                   .find(each => each[0].trim() === SHARED_LOGIN_COOKIE_NAME);
            if (cookie) {
                return cookie[1];
            }
        }
    }
    
    function isValidAuth(auth) {
        // noinspection RedundantIfStatementJS
        if (!auth || isLoggedIn()) {
            return true;
        }
        return false;
    }
}

const routes = [
    {
        //region
        path: '/',
        name: 'Layout',
        meta: {
            disableSharedLogin: true
        },
        component: () => import('@/views/Layout'),
        children: [
            {
                path: 'point-shop/goods',
                alias: '/',
                name: 'ViewPointShopGoodsList',
                component: () => import('@/views/pointshop/goods/List')
            },
            {
                path: 'point-shop/goods/:id',
                name: 'ViewPointShopGoodsDetail',
                component: () => import('@/views/pointshop/goods/Detail')
            },
            {
                path: 'point-shop/orders',
                name: 'ViewOrder',
                component: () => import('@/views/pointshop/order/Order'),
                meta: metaAuth()
            },
            {
                path: 'point-shop/orders/complete',
                name: 'ViewPointOrderComplete',
                component: () => import('@/views/pointshop/order/Complete'),
                meta: metaAuth()
            },
            {
                path: 'point-shop/orders/me',
                name: 'ViewPointShopOrderList',
                component: () => import('@/views/pointshop/order/List'),
                meta: metaAuth()
            }
        ]
    },
    //endregion
    {
        path: '/events/winter',
        name: 'WinterLandingPage',
        component: () => import('@/views/event/winter/WinterLandingPage')
    },
    {
        path:'/events/mou',
        name: 'IntegratedServiceApp',
        component: () => import('@/views/event/mou/IntegratedServiceApp')
    }
];

function metaAuth() {
    return {
        auth: {
            redirect: 'https://jajaeapt.com/bbs/login.php?url=https://support.jajaeapt.com'
        }
    }
}

routes.forEach(each => {
    each.beforeEnter = beforeGuard;
});

const router = createRouter({
    history: createWebHistory(),
    
    routes
});

export {router};