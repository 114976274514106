<template>
	<router-view :key="$route.fullPath"/>

	<button @click="routerBack"
	        class="go-back-btn">
		<i class="fas fa-arrow-left"></i>
		<strong>이전 페이지로 이동</strong>
	</button>
</template>

<script>
export default {
	name: 'App',
	watch: {
		$route() {
			window.scrollTo(0, 0);
		}
	},
	methods: {
		routerBack() {
			if (this.$route.name === 'ViewIndex') {
				return;
			}
			this.$router.back();
		}
	}
};
</script>

<style>
@import url('@/assets/default.css');
@import url('@/assets/style.css');
@import url('//cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

:root {
	--border: #E8E8E8;
	--primary: #D32F2F;
	--active: #FC4F4F;
}

body {
	overflow-y: scroll;
	
	background : #fff;
	color      : #222;
}

#app > article {
	max-width : 100%;
	min-height: 600px;
	width     : 1180px;
	margin: 0 auto;
}

a:visited {
	color: black;
}

* {
	margin  : 0;
	padding : 0;
	box-sizing: border-box;
}

ul, li {
	margin     : 0;
	padding    : 0;
	list-style : none;
}

.go-back-btn {
	position      : fixed;
	display       : block;
	top           : 50%;
	left          : 10px;
	width         : 170px;
	height        : 42px;
	line-height   : 42px;
	padding-left  : 52px;
	margin-top    : -21px;
	background    : #fff;
	color         : #222;
	border-radius : 42px;
	text-align    : left;
	border        : 0;
	font-size     : 12px;
	font-weight   : bold;
	cursor        : pointer;
	z-index       : 999;
	box-shadow    : rgba(149, 157, 165, 0.2) 0px 8px 24px;
	transition    : all 0.4s;
	animation     : motion 0.6s linear 0s infinite alternate;
}

.go-back-btn i {
	position      : absolute;
	top           : 0;
	left          : 0;
	background    : var(--primary);
	color         : #fff;
	text-align    : center;
	width         : 42px;
	height        : 42px;
	line-height   : 42px;
	border-radius : 50%;
	transition    : all 0.2s ease-in-out;
}

.go-back-btn strong {
	margin-right : 5px;
}

.go-back-btn:hover {
	color : var(--primary);
}

@media screen and (max-width : 1180px) {
	.go-back-btn {
		top : 85%;
	}
}

@keyframes motion {
	0% {
		margin-top : 0px;
	}
	100% {
		margin-top : 10px;
	}
}

</style>
