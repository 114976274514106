import Member from '@/server/response/member';

function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const sessionId = uuidv4();
export default function setInterceptor(axios) {
    useRequest();
    useResponse();
    
    function useRequest() {
        axios.interceptors.request
             .use(onFulfilled);
    
        function onFulfilled(config) {
            const member = Member.fromClient();
            if (member) {
                config.headers["X-AUTH-TOKEN"] = member.data.token;
            }
            config.headers["session-id"] = sessionId;
            return config;
        }
    }
    
    function useResponse() {
        axios.interceptors.response
             .use(onFulfilled, onRejected);
        
        function onFulfilled(response) {
            return response;
        }
    
        function onRejected(error) {
            alert(error.response.data.message);
        }
    }
}