import { createApp } from 'vue'
import App from './App.vue'

import {router} from '@/setup/router';

/** axios */
import axios from 'axios';
import setInterceptor from '@/setup/axios-interceptor';
setInterceptor(axios);

/** prototype */
import extendProtoType from '@/setup/prototype-extend';
extendProtoType();

const appInstance = createApp(App);
appInstance.use(router);
appInstance.config.globalProperties.$axios = axios;

appInstance.mount('#app')
